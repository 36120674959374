<template>
  <div>
    <v-card dense flat max-width="90%" class="mx-auto">
      <showFilteredPosts :personalMode="true" :postsStatus="'done'" :proposalsMode="true"/>
    </v-card>
    <!-- <div>
      <OneTable :mainHeader="'פורסם'" :postState="'NewPosts'" :allUsers="allUsers" :trucking="trucking" />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// import OneTable from "@/components/app/common/Table/OneTable2";

import showFilteredPosts from '@/components/showRecord/showFilteredPosts.vue'

export default {
  props: {
    allUsers: {
      default: false,
    },
    trucking: {
      default: false,
    },
  },
  components: {
    // OneTable,
    showFilteredPosts
  },
  computed: {
    ...mapGetters(["loading"]),
  },
};
</script>

<style></style>