<template>
  <div>
    <v-progress-linear color="indigo" height="10" indeterminate v-if="loading">
    </v-progress-linear>
    <div v-if="!loading">
      <v-tabs v-model="tab" hide-slider class="mr-3">
        <v-tab v-for="item in items" :key="item.tab" class="text-h6">
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-divider color="#512DA8" class="mb-3"></v-divider>

      <RFormNewRecord
        :fields="lagguageHeaders"
        :values="values"
        @close="close"
        @publishClick="publishClick"
        :buttonName="'מודע חדשה'"
      />

      <v-tabs-items v-model="tab" v-if="!loading">
        <v-tab-item>
          <v-card flat>
            <NewPosts :allUsers="false" v-if="!loading" :trucking="true" />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <InProgress v-if="!loading" :trucking="true" />
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <Done v-if="!loading" :trucking="true" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import NewPosts from "@/components/app/MyTrucking/pages/NewPosts.vue";
import InProgress from "@/components/app/MyTrucking/pages/inProgress.vue";
import Done from "@/components/app/MyTrucking/pages//Done.vue";

import RFormNewRecord from "@/components/app/common/Form/RFormNewRecord/RFormNewRecord";

export default {
  name: "MyLuggage",
  data: () => ({
    addDialog: false,
    values: {},
    tab: null,
    _dessertHeaders_inProgress: [],
    _dessertHeaders_NewPosts: [],
    items: [
      { tab: "פורסם", content: "Tab 1 Content" },
      { tab: "עבר לביצועה", content: "Tab 2 Content" },
      { tab: "בוצע", content: "Tab 3 Content" },
    ],
  }),
  mounted() {},
  methods: {
    close() {
      this.addDialog = false;
    },
    async publishClick(v) {
      await this.$store.dispatch("loading", true);
      this.addDialog = false;
      await this.$store.dispatch("AddLagguage", {
        val: v.val,
        target: v.target,
      });
      await this.$store.dispatch("loading", false);
    },
  },
  components: {
    NewPosts,
    InProgress,
    Done,
    // Tample,
    // Archive,
    RFormNewRecord,
  },
  async created() {
    await this.$store.dispatch("loading", true);
    await this.$store.dispatch("lagguageHeaders");
    await this.$store.dispatch("loading", false);
  },
  computed: {
    ...mapGetters(["loading", "lagguageHeaders"]),
  },
  // components: {
  // },
};
</script>

<style scoped>
.v-tab--active {
  border: #1565c0;
  background-color: #1565c0;
  border-style: solid;
  border-width: 0;
  /* border-radius: 15px 15px 0px 0px; */
  color: white !important;
}
.v-divider {
  border-top-width: 1px;
}
</style>
